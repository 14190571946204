import React from 'react';
import { Timeline, Typography } from 'antd';
import { useStaticQuery, graphql } from 'gatsby';

import { Metadata } from '../components';
import StandardLayout from '../layouts/standard';

const IndexPage = () => {

	const {  
		allMarkdownRemark,
		markdownRemark
	} = useStaticQuery(
		graphql`
			query {
				markdownRemark(fileAbsolutePath: {regex: "/content\/news/"}) {
					frontmatter {
			  			title
					}
					html
					htmlAst
		  		}
        		allMarkdownRemark(
					sort: {fields: frontmatter___date, order: DESC}
					filter: {fileAbsolutePath: {regex: "/news/(.+.md)/"}}
				) {
					nodes {
					  	frontmatter {
							title
							date
						}
					  	html
					}
				}
      		}
    	`
	);

	return (
		<StandardLayout>
			<Metadata title={ markdownRemark.frontmatter.title } />
			<Typography.Title level={ 2 }>News </Typography.Title>
			<Timeline mode="left">
				{
					allMarkdownRemark.nodes.map( item => {
						return <Timeline.Item label={ item.frontmatter.date }>
							<Typography.Title level={ 3 }>{ item.frontmatter.title }</Typography.Title>
							<Typography.Text><div dangerouslySetInnerHTML={ { __html: item.html } } /></Typography.Text>
						</Timeline.Item>
					})
				}
			</Timeline>
		</StandardLayout>
	)

}

export default IndexPage;
